export const authConfig = {
  clientId: import.meta.env.VITE_OAUTH2_CLIENT_ID,
  authorizationEndpoint: import.meta.env.VITE_OAUTH2_AUTHORIZATION,
  tokenEndpoint: import.meta.env.VITE_OAUTH2_TOKEN,
  redirectUri: import.meta.env.VITE_APP_URI + "/dashboard/home",
  scope: 'openid profile email ' + (import.meta.env.VITE_OAUTH2_SCOPES ?? ''),
  decodeToken: false,
  loginMethod: 'popup',
  autoLogin: false,
  storage: 'local',
  clearURL: true,
  logoutRedirect: '/',
};

export const homeUrl = import.meta.env.VITE_APP_URI;
export const baseUrl = import.meta.env.VITE_API_URL;
export const apiUrl = baseUrl + `/ajax`;
export const geoAPI = import.meta.env.VITE_GEO_API;
