import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Page from '../components/Page';
import Logo from '../components/Logo';
import { AuthContext } from 'react-oauth2-code-pkce';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function Login() {
  const { logIn } = useContext(AuthContext);

  return (
    <Page title="Login">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Logo imageHeight={200} sx={{ mx: 'auto' }} />

          <Box
            component="img"
            src="/static/illustrations/login.svg"
            sx={{ height: 260, mt: '-10px', mb: '25px', mx: 'auto' }}
          />

          <Button onClick={() => logIn('AJSAJSHASAHJSHJASAHJSHA')} size="large" variant="contained">
            Accedere
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
