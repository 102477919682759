import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

const LogoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: theme.palette.grey[500],
}));

export default function Logo({ disabledLink = false, sx, imageHeight, onClick }) {
  const theme = useTheme();

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    /*     <Box sx={{ width: '100%', backgroundColor: '#929095', borderRadius: '15px', ...sx }}>
          <img height="64" src="/static/logo_drive.png" alt="logo" />
        </Box> */
    <Box sx={sx} onClick={onClick}>
      <Link underline="none" component={RouterLink} to="/dashboard/home">
        <LogoStyle>
          <Box
            sx={{
              mx: 'auto',
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img height={imageHeight} src="/static/main_logo_bar.svg" alt="logo" />
          </Box>
        </LogoStyle>
      </Link>
    </Box>
  );

  return <>{logo}</>;
}
