import React, { useContext } from 'react';
import Router from './routes.js';
import ThemeProvider from './theme/index.js';
import ScrollToTop from './components/ScrollToTop.js';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart.js';
import Login from './pages/Login.js';
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce';
import { authConfig } from './authConfig.js';

const AuthenticatedTemplate = ({ children }) => {
  const ctx = useContext(AuthContext);
  return ctx.token !== '' ? children : null;
};

const UnauthenticatedTemplate = ({ children }) => {
  const ctx = useContext(AuthContext);
  return ctx.token === '' ? children : null;
};

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider authConfig={authConfig}>
        <div className="App">
          <AuthenticatedTemplate>
            <ScrollToTop />
            <BaseOptionChartStyle />
            <Router />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}
